$(function () {
    var visible = false;

    $('#more-less-car-brand-category').click(function (e) {
        e.preventDefault();

        if (visible) {
            $('.mao-parts-overview-more li').removeClass('show');
        } else {
            $('.mao-parts-overview-more li').addClass('show');
        }

        visible = !visible;
    });
});
