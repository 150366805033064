var zipCodeRequest = false;

var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

$('.js_zipcode').bind('keyup', function (e) {
    var data = {};
    var group = $(this).data('group');
    var groupArray = $("input[data-group='" + group + "']");
    groupArray.each(function (key, item) {
        data[$(item).attr('data-type')] = $(item).val();
    });

    delay(function () {
        if (zipCodeRequest) {
            zipCodeRequest.abort();
        }

        zipCodeRequest = $.ajax({
            type: 'POST',
            url: '/zipcode',
            dataType: 'json',
            data: data,
            success: function (result, status) {
                if (result) {
                    if (group == 'invoice') {
                        if (result['street']) {
                            $("[name='street']").val(result['street']);
                            MAO_USER.validatefield('street', result['street']);
                        }

                        if (result['city']) {
                            $("[name='city']").val(result['city']);
                            MAO_USER.validatefield('city', result['city']);
                        }

                        if (result['zipCode']) {
                            $("[name='zipCode']").val(result['zipCode']);
                            MAO_USER.validatefield('zipCode', result['zipCode']);
                        }
                    }
                    if (group == 'delivery') {
                        if (result['street']) {
                            $("[name='deliveryStreet']").val(result['street']);
                            MAO_USER.validatefield('deliveryStreet', result['street']);
                        }
                        if (result['city']) {
                            $("[name='deliveryCity']").val(result['city']);
                            MAO_USER.validatefield('deliveryCity', result['city']);
                        }
                        if (result['zipCode']) {
                            $("[name='deliveryZipCode']").val(result['zipCode']);
                            MAO_USER.validatefield('deliveryZipCode', result['zipCode']);
                        }
                    }
                }

                zipCodeRequest = false;
            },
        });
    }, 1000);
});
