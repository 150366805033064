$('button#newsletterjq').click(function () {
    dataString = $('#newsletterEMailAddress').serialize();
    $.ajax({
        type: 'POST',
        url: route.newssubscriber.subscribe,
        data: dataString,
        dataType: 'json',

        // show form request validation failures (422)
        error: function (data) {
            var errName = $('#notification-newsSubscriber');
            var errorsHtml = '<ul>';
            var errors = data.responseJSON;
            $.each(errors, function (key, value) {
                errorsHtml += '<li><i class="fa fa-exclamation-triangle"></i><span>' + value[0] + '</span></li>';
            });
            errorsHtml += '</ul>';

            errName.removeClass('ticked');
            errName.addClass('mao-notification warn');

            errName.html(errorsHtml);
        },

        // show http success message (200)
        success: function (data) {
            var errName = $('#notification-newsSubscriber');
            if (data.status == 'success') {
                errName.removeClass(' warn fa fa-exclamation-triangle');
                errName.addClass('mao-notification ticked');

                errName.html('<span>' + data.message + '</span>');
            } else if (data.status == 'failure') {
                errName.removeClass('ticked');
                errName.addClass('mao-notification warn');

                errName.html('<i class="fa fa-exclamation-triangle"></i><span>' + data.message + '</span>');
            }
        },
    });
    return false;
});
