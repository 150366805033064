$('html, body').scrollTop(0);

var setCartSummaryWidth = function () {
    if (window.innerWidth < 768) {
        // mobile here
        $('.cart-summary').css('width', 'auto');
    } else {
        var _w = $('.cart-summary').parent().innerWidth() - 30;
        $('.cart-summary').css('width', _w);
    }
};

var initializeWaypoints = function () {
    $('.mao-main-container').waypoint('destroy');

    if (window.innerWidth > 767) {
        var waypoints = $('.cart-summary').waypoint({
            handler: function (direction) {
                if (direction == 'down') {
                    $('.cart-summary').addClass('stuck');
                } else {
                    $('.cart-summary').removeClass('stuck');
                }
            },
            offset: '0',
        });

        var _delta;

        _delta = 60;

        if (window.innerWidth > 1100) {
            _delta = 100;
        }
        if (window.innerWidth > 1200) {
            _delta = 180;
        }

        var offset = $('.mao-main-container').height() - $('.cart-summary').height() - _delta;

        var waypoints1 = $('.mao-main-container').waypoint({
            handler: function (direction) {
                if (direction == 'down') {
                    $('.cart-summary').removeClass('stuck');
                    $('.cart-summary').addClass('absoluted');
                    $('.cart-summary').css('top', offset);
                } else {
                    $('.cart-summary').addClass('stuck');
                    $('.cart-summary').removeClass('absoluted');
                    $('.cart-summary').css('top', 0);
                }
            },
            offset: -offset,
        });
    }
};

setCartSummaryWidth();

$(window).on('resize', function () {
    setCartSummaryWidth();
    initializeWaypoints();
});

initializeWaypoints();

$('.js_mao-form-accountType').collapse({
    toggle: false,
});

$('.js_mao-form-addressType').collapse({
    toggle: false,
});

$('.js_mao-form-accountRadio').on('change', function (e) {
    if ($(this).prop('value') == 'particulier') {
        $('.js_mao-form-accountType').collapse('hide');
    } else {
        $('.js_mao-form-accountType').collapse('show');
    }
});

$('.js_mao-form-addressRadio').on('change', function (e) {
    if ($(this).prop('value') == 0) {
        $('.js_mao-form-addressType').collapse('show');
    } else {
        $('.js_mao-form-addressType').collapse('hide');
    }
});

if ($('.tooltip-checkout-trigger').length > 0) {
    $('.tooltip-checkout-trigger').on('click', function (e) {
        $(this).next().addClass('shown');
    });
    $('.mao-tooltip-close').on('click', function (e) {
        $(this).closest('.mao-tooltip').removeClass('shown');
    });
}
