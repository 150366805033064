if ($('.mao-searchInput--des33').length > 0) {
    var _v;
    $('.mao-searchInput--des33').on('keyup', '.mao-searchInput__input', function (e) {
        if ($(this).val() !== '') {
            showSuggestions($(this).val());
        } else {
            hideSuggestions();
        }
    });

    function showSuggestions(searchQuery) {
        let ajaxRequest;

        if (searchQuery.length > 2) {
            // Abort previous ajax call
            if (ajaxRequest != null) {
                ajaxRequest.abort();
            }

            // Load results
            ajaxRequest = $.get('/carsearch/' + encodeURIComponent(searchQuery), function (h) {
                MAO_MAIN.closeAllHeaderFlyouts();
                $('#carpartsSearchResults').html(h);
                $('.mao-searchInput--des33').addClass('has-suggest');
            }).fail(function (e) {
                $('.mao-searchInput--des33').removeClass('has-suggest');
            });
        }
    }

    // hideSuggestions:
    function hideSuggestions() {
        $('html').removeClass('has-suggest');
    }
    $('.mao-searchInput--des33').on('click', '.js__flyout-close', function (e) {
        $('.mao-searchInput--des33').removeClass('has-suggest');
        $('.mao-searchInput--des33 .mao-searchInput__input').val('');
    });

    $('.js__stickydes33trigger').waypoint(function (dir) {
        $('#pdp_sticky_bar').toggleClass('opened');
    });

    $('.psb_links').on('click', 'li', function (e) {
        $('.psb_links li').removeClass('active');
        $(this).addClass('active');

        MAO_MAIN.scrollToElement($($(this).data('hook')), -100);
    });
}
