$('.js_mao-form-accountType').collapse({
    toggle: false,
});

$('.js_mao-form-addressType').collapse({
    toggle: false,
});

$('.js_mao-form-accountRadio').on('change', function (e) {
    if ($(this).prop('value') == 'particulier') {
        $('.js_mao-form-accountType').collapse('hide');
    } else {
        $('.js_mao-form-accountType').collapse('show');
    }
});

$('.js_mao-form-addressRadio').on('change', function (e) {
    if ($(this).prop('value') == 0) {
        $('.js_mao-form-addressType').collapse('show');
    } else {
        $('.js_mao-form-addressType').collapse('hide');
    }
});

$('.validate_field')
    .off('blur')
    .on('blur', null, function () {
        var fieldvalue = $(this).val();
        var fieldname = $(this).attr('name');
        try {
            MAO_USER.validatefield(fieldname, fieldvalue);
        } catch (e) {
            console.log(e);
        }
    });

$('.checkout_country_update')
    .off('change')
    .on('change', null, function (e) {
        e.preventDefault();
        if ($(this).prop('value') == 56) {
            $('.js_mao-form-address-bus').collapse('show');
        } else {
            $('.js_mao-form-address-bus').collapse('hide');
        }
    });

$('.checkout_delivery_country_update')
    .off('change')
    .on('change', function (e) {
        e.preventDefault();
        if ($(this).prop('value') == 56) {
            $('.js_mao-form-address-delivery-bus').collapse('show');
        } else {
            $('.js_mao-form-address-delivery-bus').collapse('hide');
        }
    });
