var markers = [];
var shops = [];
var selectedLocation = false;
let mapCenter, componentRestrictions, map, infowindow, autocomplete;
export let maoInitMap = function () {
    var siteId = $("#map").data("site-id");

    if (siteId == "2") {
        mapCenter = { lat: 50.503887, lng: 4.469935 };
        componentRestrictions = { country: "BE" };
    } else {
        mapCenter = { lat: 52.3622356307, lng: 4.9070692062 };
        componentRestrictions = { country: "NL" };
    }

    map = new google.maps.Map($("#map")[0], {
        zoom: 8,
        center: mapCenter,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        mapTypeControl: false,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.TOP_LEFT,
        },
        zoomControl: true,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
        },
        streetViewControl: false,
    });

    infowindow = new google.maps.InfoWindow();
    autocomplete = new google.maps.places.Autocomplete($("#search")[0], {
        componentRestrictions: componentRestrictions,
    });

    autocomplete.addListener("place_changed", function () {
        var place = autocomplete.getPlace();

        if (place.geometry && place.geometry.location) {
            // Selected from list
            geoCodeLocation(
                place.geometry.location.lat(),
                place.geometry.location.lng(),
            );
        } else {
            // Entry without selection
            geoCodeName(place.name);
        }
    });

    $("#searchLocation").on("click", handleSearch);
    $("#locatorForm").on("submit", function () {
        return false;
    });

    // Zipcode / city prefilled? Perform search
    if ($("#search").val() != "") {
        geoCodeName($("#search").val());
    }
};

let listLocations = function (response) {
    deleteAllMarkers();

    var html = "";
    if (response.results.length > 0) {
        $.each(response.results, function (i, shop) {
            html += '<li data-index="' + i + '">';
            html += '<a href="#">';
            html += '<p class="mao-h3">' + shop.Name + "</p>";
            html += "<p>";
            html += shop.Address.Street + " " + shop.Address.HouseNr + "<br />";
            html += shop.Address.Zipcode + " " + shop.Address.City;
            html += "</p>";
            html +=
                '<p class="distance hidden-xs">Afstand: ' +
                parseFloat(shop.Distance / 1000)
                    .toFixed(2)
                    .replace(",", ".") +
                "&nbsp;km</p>";
            html +=
                '<div class="buttonContainer"><button class="visible-xs mao-button black full-w" onclick="storeSelectedLocation(\'' +
                shop.LocationCode +
                "', '" +
                shop.LocationProvider +
                "')\">";
            html += '<i class="fa fa-arrow-right"></i> Doorgaan</button>';
            html += "</div></a>";
            html += "</li>";

            if (typeof shop.Marker === "undefined") {
                shop.Marker = "marker-postnl.png";
            }

            var location = {
                lat: shop.Latitude,
                lon: shop.Longitude,
                marker: shop.Marker,
            };
            addMarker(location, i, 10);
            shops.push(shop);
        });
    }

    $("ol.locations").html(html);
};

var geoCodeName = function (name) {
    $.get("/locator-name-results", { name: name }, function (h) {
        listLocations(h);

        map.setCenter(new google.maps.LatLng(h.latitude, h.longitude));
        map.setZoom(12);
    });
};

var geoCodeLocation = function (latitude, longitude) {
    $.get(
        "/locator-results",
        { lat: latitude, lon: longitude },
        function (h) {
            listLocations(h);

            map.setCenter(new google.maps.LatLng(h.latitude, h.longitude));
            map.setZoom(12);
        },
        "json",
    );
};

var handleSearch = function (e) {
    e.preventDefault();

    if (!place) {
        var place = autocomplete.getPlace();
    }

    if (place && place.geometry && place.geometry.location) {
        // Selected from list
        geoCodeLocation(
            place.geometry.location.lat(),
            place.geometry.location.lng(),
        );
    } else {
        // Entry without selection
        if (place && place.name) {
            geoCodeName(place.name);
        } else {
            geoCodeName($("#search").val());
        }
    }
};

var scrollToItemInView = function () {};

var deleteAllMarkers = function () {
    $.each(markers, function (i, marker) {
        marker.setMap(null);
    });
    shops = [];
    markers = [];
};

var addMarker = function (location, index, timeout) {
    var markerPosition = new google.maps.LatLng(location.lat, location.lon);

    setTimeout(function () {
        var marker = new google.maps.Marker({
            position: markerPosition,
            icon: "/images/assets/icons/" + location.marker,
            map: map,
            id: "location" + index,
            animation: google.maps.Animation.DROP,
        });

        markers.push(marker);

        google.maps.event.addListener(
            marker,
            "click",
            (function (marker, index) {
                return function () {
                    infowindow.close(map, marker);
                    setSelectedMarkerLocation(index, true);
                };
            })(marker, index),
        );

        google.maps.event.addListener(
            marker,
            "mouseover",
            (function (marker, index) {
                return function () {
                    markerMouseOver(index);
                    scrollToItemInView(index, function () {
                        $(".locations li").removeClass("hover");
                        $(".locations li:eq(" + index + ")").addClass("hover");
                    });
                };
            })(marker, index),
        );

        google.maps.event.addListener(
            marker,
            "mouseout",
            (function (marker, index) {
                return function () {
                    markerMouseOut(index);
                    $(".locations li").removeClass("hover");
                    // infowindow.close(map, marker);
                    // infowindow.setZIndex();
                };
            })(marker, index),
        );
    }, timeout);

    //limits.extend(markerPosition);
};

var addAllMarkers = function () {
    let limits = new google.maps.LatLngBounds();

    $.each(locations, function (index, location) {
        addMarker(location, index, index * 65); // index * 60
    });

    map.fitBounds(limits);
};

var markerMouseOver = function (index) {
    $(".locations li").removeClass("hover");
    $(".locations li:eq(" + index + ")").addClass("hover");
    $.each(markers, function (i, marker) {
        if (i != index) {
            marker.setOpacity(0.5);
        } else {
            marker.setZIndex(999);
        }
    });
};

var markerMouseOut = function (index) {
    $.each(markers, function (i, marker) {
        marker.setOpacity(1);
    });
};

var setSelectedMarkerLocation = function (index, scroll) {
    $.each(markers, function (i, marker) {
        marker.setZIndex();
    });

    var marker = markers[index];
    marker.setZIndex(999);

    $(".locations li:eq(" + index + ")").addClass("hover");

    let shop = shops[index];
    selectedLocation = shop.LocationCode;
    map.setCenter(new google.maps.LatLng(shop.Latitude, shop.Longitude));
    map.setZoom(15);

    var html = "";
    html += '<div class="markerWindow">';
    html += '<p class="mao-h3">' + shop.Name + "</p>";
    html += "<p>";
    html += shop.Address.Street + " " + shop.Address.HouseNr + "<br />";
    html += shop.Address.Zipcode + " " + shop.Address.City;
    html += "</p>";
    html +=
        '<button class="mao-button yellow full-w" onclick="storeSelectedLocation(\'' +
        shop.LocationCode +
        "', '" +
        shop.LocationProvider +
        '\')"><i class="fa fa-arrow-right"></i> Doorgaan</button>';
    html += "<div>";

    var days = [
        { dayName: "Monday", nlName: "Maandag" },
        { dayName: "Tuesday", nlName: "Dinsdag" },
        { dayName: "Wednesday", nlName: "Woensdag" },
        { dayName: "Thursday", nlName: "Donderdag" },
        { dayName: "Friday", nlName: "Vrijdag" },
        { dayName: "Saturday", nlName: "Zaterdag" },
        { dayName: "Sunday", nlName: "Zondag" },
    ];

    var addressBlock = "";
    addressBlock += '<div class="row">';
    addressBlock += '<div class="col-md-6">';
    addressBlock +=
        '<p class="mao-h3" style="margin: 10px 0">' + shop.Name + "</p>";
    addressBlock += "<p>";
    addressBlock += shop.Address.Street + " " + shop.Address.HouseNr + "<br />";
    addressBlock += shop.Address.Zipcode + " " + shop.Address.City + "<br />";
    addressBlock += shop.PhoneNumber ? shop.PhoneNumber : "";
    addressBlock += "</p>";
    addressBlock +=
        '<button class="mao-button yellow full-w" onclick="storeSelectedLocation(\'' +
        shop.LocationCode +
        "', '" +
        shop.LocationProvider +
        '\')"><i class="fa fa-arrow-right"></i> Doorgaan</button>';
    addressBlock += "</div>";
    addressBlock += '<div class="col-md-6">';
    addressBlock +=
        '<p class="mao-h3" style="margin: 10px 0">Openingstijden komende week</p><table width="100%">';

    for (let day in days) {
        if (shop.OpeningHours[days[day].dayName]) {
            addressBlock +=
                "<tr><td>" +
                days[day].nlName +
                "</td><td>" +
                shop.OpeningHours[days[day].dayName].string +
                "</td></tr>";
        }
    }

    addressBlock += "</table></div>";
    addressBlock += "</div>";

    $("#addressPlaceHolder").html(addressBlock);

    infowindow.setContent(html);
    infowindow.setZIndex(999);
    infowindow.open(map, marker);
};

export var storeSelectedLocation = function (
    selectedLocationCode,
    selectedLocationProvider,
) {
    $.post(
        "/store-pickup-location",
        {
            locationCode: selectedLocationCode,
            locationProvider: selectedLocationProvider,
        },
        function (h) {
            document.location.href = "/betaalwijze/";
        },
        "json",
    );
};

$(document).on("click", ".locations a", function (e) {
    e.preventDefault();
    $(".locations li").removeClass("hover");
    setSelectedMarkerLocation($(this).parent().data("index"));
});

$(document)
    .on("mouseover focus", ".locations li", function (e) {
        markerMouseOver($(this).data("index"));
    })
    .on("mouseout blur", ".locations li", function () {
        markerMouseOut($(this).data("index"));
    });
