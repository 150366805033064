$('html, body').scrollTop(0);

var getAmSlides = function () {
    var spv;
    var _w = window.innerWidth;
    if (_w < 768) {
        spv = 2;
    } else if (_w < 1024) {
        spv = 3;
    } else {
        spv = 3;
    }
    return spv;
};

var setCartSummaryWidth = function () {
    if (window.innerWidth < 768) {
        // mobile here
        $('.cart-summary').css('width', 'auto');
    } else {
        var _w = $('.cart-summary').parent().innerWidth() - 30;
        $('.cart-summary').css('width', _w);
    }
};

var initializeWaypoints = function () {
    $('.mao-main-container').waypoint('destroy');

    if (window.innerWidth > 767) {
        var waypoints = $('.mao-main-container').waypoint({
            handler: function (direction) {
                if (direction == 'down') {
                    $('.cart-summary').addClass('stuck');
                } else {
                    $('.cart-summary').removeClass('stuck');
                }
            },
            offset: '0',
        });

        var _delta;

        _delta = 60;

        if (window.innerWidth > 1100) {
            _delta = 100;
        }
        if (window.innerWidth > 1200) {
            _delta = 180;
        }

        var offset = $('.mao-main-container').height() - $('.cart-summary').height() - _delta;

        var waypoints1 = $('.mao-main-container').waypoint({
            handler: function (direction) {
                if (direction == 'down') {
                    $('.cart-summary').removeClass('stuck');
                    $('.cart-summary').addClass('absoluted');
                    $('.cart-summary').css('top', 0);
                } else {
                    $('.cart-summary').removeClass('absoluted');
                    $('.cart-summary').css('top', 0);
                }
            },
            offset: -offset,
        });
    }
};

setCartSummaryWidth();

$(window).on('resize', function () {
    setCartSummaryWidth();
});

initializeWaypoints();

$('.cart-table').on('click', '.cart-table-item__remove', function (e) {
    e.preventDefault();
    let item = $(this).closest('.cart-table-item');
    item.slideUp('slow', function () {
        item.remove();
        if ($('.cart-table-item__remove').length === 0) {
            $('#shippingContainer').hide();
        }
    });
});
