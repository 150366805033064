import './vendor/jquery.dotdotdot.js';
import './vendor/swiper.jquery.min.js';
import './vendor/jquery.putcursoratend.js';
import './vendor/lazyload.js';
import './bs/bootstrap.js';
import './modules/newsletter.js';
import './vendor/modernizr.js';
import './vendor/waypoints.js';
import './vendor/bootstrap-slider.js';
import 'bootstrap4-toggle';

import './account.js';
import './automerk.js';
import './checkout.js';
import './zipcode.js';
import './pdp.js';
import './sort.js';
import './cart.js';
import './lister.js';
import './mainmodels.js';
import './body.js';
import {maoInitMap, storeSelectedLocation} from './locator.js';
import $ from 'jquery';

window.maoInitMap = maoInitMap;
window.storeSelectedLocation = storeSelectedLocation;
$.fn.modal.Constructor.prototype.enforceFocus = function () {};

import.meta.glob(['/resources/assets/fonts/*']);

$('#carouselOnderdelen').slick({
    variableWidth: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: true,
    infinite: false,
    prevArrow: '<span class="arrow-carousel prev  fa fa-chevron-left"></span>',
    nextArrow: '<span class="arrow-carousel next  fa fa-chevron-right"></span>',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
});
var lastScrollTop = 0;

$(window).scroll(function () {
    var scrollPosition = $(window).scrollTop();
    if (scrollPosition > 550) {
        // Adjust the value as needed
        $('.sticky_filter_bar').fadeIn(); // Show the sticky element
    } else {
        $('.sticky_filter_bar').fadeOut(); // Hide the sticky element
    }
    if (scrollPosition > 550 && scrollPosition < lastScrollTop) {
        // Adjust the value as needed
        $('.sticky_filter_bar_h').fadeIn(); // Show the sticky element
    } else {
        $('.sticky_filter_bar_h').fadeOut(); // Hide the sticky element
    }
    lastScrollTop = scrollPosition;
});
