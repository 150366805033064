var slide = 1;
var slideTimeout = 2500;
var paused = false;
var currentImageIndex = 0;

var mainCarousel;
var modalCarousel;
let widget;
$('#pdp_zoom_modal')
    .on('show.bs.modal', function () {
        $($(this).find('.pmp_gutter li').get(currentImageIndex)).click();
        modalCarousel.currentSlide = mainCarousel.currentSlide;
        modalCarousel.showSlide(mainCarousel.currentSlide);
    })
    .on('shown.bs.modal', function () {
        modalCarousel.currentSlide = mainCarousel.currentSlide;
        modalCarousel.showSlide(mainCarousel.currentSlide);
    });
$('#Modal').on('hidden.bs.modal', function () {
    try {
        widget.destroy();
    } catch (e) {}

    $(`#Modal`).find('.modal-body').html('');
});
//pdp zoom modal

$('.pdp_zoom_trigger--click').on('click', function (e) {
    if (
        mainCarousel.carousel.eq(mainCarousel.currentSlide).attr('data-360-images') == undefined ||
        $(this).parent().attr('id') == 'pdp_zoom_trigger'
    ) {
        $('#pdp_zoom_modal').modal();
        modalCarousel.carousel.removeClass('active');
        modalCarousel.currentSlide = mainCarousel.currentSlide;
        modalCarousel.showSlide(mainCarousel.currentSlide);
    }
});

$('.btnRemindMe').on('click', function (e) {
    // Prevent submits and default form behaviour
    e.preventDefault();

    var id = $(this).data('id');
    var mail = $('form[data-form=' + id + ']')
        .find('[name="remindMeEmailAddress"]')
        .first()
        .val();

    // Initial state
    $('#remindMe' + id)
        .find('.invalidMail')
        .first()
        .addClass('hide');
    $('#remindMe' + id)
        .find('.remindMeSuccess')
        .first()
        .addClass('hide');

    // Check valid mail
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail.toString())) {
        $('#remindMe' + id)
            .find('.invalidMail')
            .first()
            .removeClass('hide');
    } else {
        $(this).attr('disabled', 'disabled');

        $.post(
            $(this).data('href'),
            {emailAddress: mail},
            function (h) {
                //
            },
            'json'
        );

        // Submit mail and confirm
        $('#remindMe' + id)
            .find('.remindMeSuccess')
            .first()
            .removeClass('hide');

        // Hide form
        $('form[data-form=' + id + ']').hide();
    }
});

$('.js__pdpAddToCart').on('click', function (event) {
    let btnText = $(event.target).html();
    $(event.target).html('<i class="fa fa-spinner fa-spin"></i> Bestellen');
    let productFragment = $(this).closest('.row');
    $.when(MAO_Shopping_Cart.add(productFragment)).then(function () {
        $(event.target).html(btnText);
    });
});

$('.js__pdpAddToCart_multi').on('click', function (event) {
    var product = {
        catalogNr: $(this).data('catalognr'),
        localBrandId: $(this).data('localbrandid'),
        supplierCode: $(this).data('suppliercode'),
        priceChoice: $(this).data('choice'),
        href: document.location.href,
        depotId: $(this).data('depot'),
        orderQuantity: $(this).parent().parent().find('.mao-select').val(),
    };

    let btnText = $(event.target).html();
    $(event.target).html('<i class="fa fa-spinner fa-spin"></i> Bestellen');

    $.when(MAO_Shopping_Cart.addSingleProduct(product)).then(function () {
        $(event.target).html(btnText);
    });
});

$('.mao-addToCartModal__products').on('click', '.mao-button .yellow', function () {
    $(this).addClass('added');
});

//pdp sticky
if ($('#pdp_sticky_bar').length > 0) {
    $('.pdp-addToCart').waypoint(function (dir) {
        $('#pdp_sticky_bar').toggleClass('opened');
    });
}

if ($('.psb_links').length > 0) {
    $('.psb_links').on('click', 'li', function (e) {
        $('.psb_links li').removeClass('active');
        $(this).addClass('active');

        MAO_MAIN.scrollToElement($($(this).data('hook')), -140);
    });
}
// sticky bar pdp button scroll to top

if ($('#pdp_sticky_bar').length > 0) {
    $('#pdp_sticky_bar .mao-button-sticky').on('click', function (e) {
        e.preventDefault();
        var productFragments = {0: $(this).closest('#pdp_sticky_bar')};
        MAO_Shopping_Cart.add(productFragments);
    });
}

$('.pdp-summary-all').on('click', function (e) {
    e.preventDefault();
    MAO_MAIN.scrollToElement($(this).attr('href'), -140);
});

if ($('#mm_photos').length > 0) {
    var items_nr = $('#mm_photos #mmp_gut .mm_photo').length;
    // set the rail width
    $('#mmp_gut').css('width', 100 * items_nr + '%');
    //set the item width
    $('#mm_photos #mmp_gut .mm_photo').css('width', Math.floor(100 / items_nr) + '%');

    //var myScroll_mob = new IScroll('#mm_photos', { eventPassthrough: true, scrollX: true, scrollY: false, preventDefault: false });

    var myScroll_mob = new IScroll('#mm_photos', {
        scrollX: true,
        scrollY: false,
        momentum: false,
        snap: true,
        snapSpeed: 400,
        keyBindings: true,
        eventPassthrough: true,
        preventDefault: false,
    });

    var st;
    $('.mm_photos_pager span').on('click', function (e) {
        $('.mm_photos_pager span').removeClass('active');
        $(this).addClass('active');

        myScroll_mob.goToPage(parseInt($(this).text()) - 1, 0);
    });

    myScroll_mob.on('scrollEnd', function () {
        currentImageIndex = this.currentPage.pageX;
        $('.mm_photos_pager span').removeClass('active');
        $($('.mm_photos_pager span').get(currentImageIndex)).addClass('active');
    });
}

$('.js__wishlist-trigger').on('click', function (e) {
    e.preventDefault();

    if ($(this).data('localbrand') && $(this).data('catalognr')) {
        MAO_Account.addToWishList($(this).data('localbrand'), $(this).data('catalognr'));
    } else {
        MAO_Account.addItem($(this).closest('.row'));
    }
});

$('.mao-usage-collapseTrigger').on('click', function (e) {
    var elm = $(this);
    if ($(this).find('li').length == 0) {
        $.post(
            '/types',
            {
                modelId: $(this).data('model-id'),
                productId: $(this).data('product-id'),
            },
            function (h) {
                $(elm).find('.collapse > ul').html(h);
            },
            'html'
        );
    }
});

$('.pdp_specs_list > li').on('click', function (e) {
    if ($(e.target).is('.mao-usage-collapseTrigger')) {
        $(this).find('a').click();
    }
});

$(function () {
    if ($('#types-placeholder').length) {
        $.get(
            '/applicability',
            {
                productId: $('#types-placeholder').data('product-id'),
            },
            function (h) {
                $('#types-placeholder').html(h);
            },
            'html'
        );
    }
});

$('.modalTrigger').on('click', function (event) {
    if (widget != null) {
        widget.destroy();
    }
    const images = $('#' + event.currentTarget.id).attr('data-360-images');
    var clone;
    if (images != undefined) {
        clone =
            `<div class="wrapper">\n` +
            `                                        <div><div class="threesixty" id="threesixty"></div></div>\n` +
            `                                        <div class="pdp_media_pager pmp_gutter">\n` +
            `                                            <ul style="\n` +
            `                        font-size: 20px;\n` +
            `                        color: #A6A7A9;\n` +
            `                    ">\n` +
            `                                                <li id="prevModal" class="fa fa-reply"></li>\n` +
            `                                                <li id="nextModal" class="fa fa-share"></li>\n` +
            `                                                <li id="360pause" class="fa fa-pause" style="margin-bottom: 10px;"></li>\n` +
            `                                            </ul>\n` +
            `                                        </div>\n` +
            `                                    </div>`;
        $(`#Modal`).find('.modal-body').html(clone);

        widget = new Widget360('threesixty', images, '360pause', 'prevModal', 'nextModal');
    } else if ($('#' + event.currentTarget.id).attr('asset-url') != undefined) {
        clone = `<iframe class="youtube"  src="${$('#' + event.currentTarget.id).attr('asset-url')}"> </iframe>`;
        $(`#Modal`).find('.modal-body').html(clone);
    }

    $(`#Modal`).modal();
});

$(document).ready(function () {
    mainCarousel = new carousel($('#carouselMain'), 'threesixty', '#outputCarousel');
    modalCarousel = new carousel($('#carouselModal'), 'threesixty2', '#outputModal');
    $('#toggleModalCar').on('click', function () {
        MAO_MAIN.initCarComponent();
        MAO_CAR.initSelects();
    });

    $('#modalSearchCar')
        .on('show.bs.modal', function () {
            MAO_MAIN.initCarComponent();
            MAO_CAR.initSelects();
        })
        .on('shown.bs.modal', function () {
            MAO_MAIN.initCarComponent();
            MAO_CAR.initSelects();
        });
    window.onChangeOptionQty = function ChangeOptionQty() {
        window.lastoptionQtyUsed = $(this).attr('id');
        if ($(this).val() == 'meer') {
            $(this).val($(this).attr('lastOptionQty')).change();
            $('#modalInputQty').attr('step', $(this).attr('orderQuantity')).val($(this).val());
            $(`#optionMore`).modal();
        } else {
            if ($(this).val() != $(this).attr('lastOptionQty') && window.lastoptionQtyUsed != 'pdpOptionQty') {
                var item = $(`#${window.lastoptionQtyUsed}`).closest('.cart-table-item');
                MAO_Shopping_Cart.updateCartItem(item.data('local_brand_id'), item.data('catalog_nr'), $(this).val());
            }
            $(this).attr('lastOptionQty', $(this).val());
        }
    };

    $('.optionQty__js').off('change').on('change', window.onChangeOptionQty);

    //make var that get id of last changed option
    $('#modalSubmitQty')
        .off('click')
        .on('click', function () {
            let lastoptionQtyUsed = $(`#${window.lastoptionQtyUsed}`);
            var val = $('#modalInputQty').val();
            if (val > lastoptionQtyUsed.attr('orderquantity') * 10) {
                $(`#${window.lastoptionQtyUsed} option:last`).before(`<option>${val}</option>`);
            }
            lastoptionQtyUsed.val(val).attr('lastOptionQty', val);
            $(`#optionMore`).modal('hide');
            if (window.lastoptionQtyUsed != 'pdpOptionQty' || window.lastoptionQtyUsed != 'pdpOptionQty2') {
                var item = lastoptionQtyUsed.closest('.cart-table-item');
                MAO_Shopping_Cart.updateCartItem(
                    item.data('local_brand_id'),
                    item.data('catalog_nr'),
                    $(`#${window.lastoptionQtyUsed}`).attr('lastOptionQty')
                );
            }
        });

    $('#modalInputQty')
        .off('change')
        .on('change', function () {
            let lastoptionQtyUsed = $(`#${window.lastoptionQtyUsed}`);
            let increment = parseInt(lastoptionQtyUsed.attr('orderQuantity'));
            if (lastoptionQtyUsed == '') {
                $(`#optionMore`).modal('hide');
            }

            if (parseInt($(this).val()) < 1) {
                $(this).val(1);
            } else {
                if ($(this).val() % increment !== 0) {
                    $(this).val(Math.ceil($(this).val() / increment) * increment);
                }
                $(this).attr('lastOptionQty', $(this).val());
            }
        });
});
